.font-title {
  font-family: 'Trebuchet MS';
}

.custom-image {
    height: 500px;
    object-fit: cover;
}

@media (min-width: 1024px) { /* Quando a tela for maior que 1023px (desktop) */
  .view-img-product {
    height: 400px;
  }
  .portfolio {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) { /* Quando a tela for menor que 768px (tablet) */
  .custom-image {
    height: 200px;
  }
  .view-img-product {
    height: 280px;
  }
  .portfolio {
    width: 100%;
  }
}

@media (max-width: 767px) { /* Quando a tela for menor que 768px (dispositivos móveis) */
  .custom-image {
    height: 200px;
  }
  .view-img-product {
    height: 280px;
  }
  .portfolio {
    width: 100%;
  }
}

.header-search {
  max-width: 100%;
  margin-bottom: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.main-search {
  background-color: black;
  color: white;
  border: solid white 1px;
}

.main-search:focus {
  background-color: black;
  border: solid white 1px;
  color: white;
}

.btn-main-search {
  background: transparent;
  border: solid white 1px;
}

.navbar-toggler {
  color: transparent;
  border: none;
  width: 40px;
  margin-left: -10px;
}

.btn-main-search:hover {
  background: transparent;
  border: solid white 1px;
  transform: scale(1.1);
}

.cart-icon {
  transform: scale(1);
}

.cart-icon:hover {
  transform: scale(1.1);
}

.social-icon {
  transform: scale(1);
}

.social-icon:hover {
  transform: scale(1.5);
}

.produto {
  border-color: #E6E6E6;
  background: #fff;
  transform: scale(1);
}

.produto:hover {
  cursor: pointer;
  background: #fff;
  transform: scale(1.03);
}

.shop-menu {
  transform: scale(1);
}

.shop-menu:hover {
  transform: scale(1.2);
}

.col-menu {
  transition: width 0.3s ease-in-out;
}

.link-no-underline {
  text-decoration: none;
}

.icon-hover:hover {
  border-color: #3b71ca !important;
  background-color: white !important;
  color: #3b71ca !important;
  }
  
.icon-hover:hover i {
  color: #3b71ca !important;
}

.btn-add-cart, 
.btn-continue,
.btn-checkout,
.btn-shop,
.btn-contact {
  background: #7401DF;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-checkout:disabled {
  background: #7401DF;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-pagination {
  background: #D3D3D3;
  border: none;
  color: #000;
  transform: scale(1);
}

.btn-pagination:hover {
  background: #D3D3D3;
  border: none;
  color: #000;
  transform: scale(1.1);
}

.btn-pagination:disabled {
  background: #D3D3D3;
  border: none;
  color: #000;
  transform: scale(1);
}

.btn-trash {
  background: #fff;
  border: solid black 1px;
  color: #000;
}

#customRange1::-webkit-slider-thumb {
  background-color: #7401DF;
  transform: scale(1);
}

#customRange1::-webkit-slider-thumb:hover {
  background-color: #7401DF;
  transform: scale(1.2);
}

#flexSwitchCheckDefault {
  cursor: pointer;
  height: 18px;
}

#flexSwitchCheckDefault:checked {
  border: none;
  background-color: #7401DF;
  transform: scale(1);
}

#customRange1::-moz-range-thumb {
  background-color: #7401DF; 
}

.btn-add-cart:hover, 
.btn-continue:hover,
.btn-checkout:hover,
.btn-shop:hover,
.btn-contact:hover {
  background: #7401DF;
  border: none;
  color: #fff;
  transform: scale(1.1);
}

.telephone-cart {
  border: solid #000 1px;
  font-size: 18px;
  font-weight: bold;
}

.btn-trash:hover {
  background: #000;
  border: solid white 1px;
  color: #fff;
}

.rounded-2:hover {
  cursor: pointer;
}

.custom-tabs .nav-link.active,
.custom-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid #A901DB;
  background-color: rgb(248,249,250);
}

.slider-clients {
  margin:0 20px;
  overflow:"hidden";
  padding:1rem 0;
  width: 100%;
}

.slider-clients img {
  height: 90px;
}

.slider-portfolio {
  margin:0 15px;
  overflow:"hidden";
  padding:2rem 0;
  text-align: center;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.react-multi-carousel-item .slider-clients {
  opacity: 0.6;
}

.card-portfolio {
  transform: scale(1);
}

.card-portfolio:hover {
  transform: scale(1.2);
}

.card-portfolio .img-fluid {
  padding: 0px;
}

.card-portfolio:hover {
  opacity: 1;
}

/* Painel Administrativo */

.scrollable-card-body {
  max-height: calc(100vh - 25px); 
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-container {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-container {
  max-height: calc(100vh - 25px);
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-container-view {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.main {
  width: 100vw;
  height: 100vh; 
  overflow: hidden; 
  background: #000;
}

.login {
  width: 100vw;
  height: 100vh; 
  overflow: hidden; 
  background: black;
}

.login-card {
  background: black;
  color: #D3D3D3;
}

.menu-button {
  overflow: hidden;
  text-align: right;
  position: relative;
  z-index: 2;
}

.menu {
  background: transparent; 
  color: white; 
  height: 100%;
  border: none;
  z-index: 1;
  width: 100%;
}

.btn-main {
  color: white; 
  cursor: pointer;
  margin-right: 15px;
  margin-top: 5px;
}

.btn-toggle {
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-main:hover {
  transform: scale(1.2);
}

.btn-toggle:hover {
  transform: scale(1.2);
}

.menu-container {
  height: 100vh; 
  overflow: hidden;
  background: #fff;
  padding: 0px 0px;
}

.menu-container-closed {
  width: 100%;
  padding: 0px;
  padding-right: 10px;
}

.col-menu-admin {
  transition: width 0.2s ease-in-out;
  padding: 0px 0px;
}

.user-icons {
  border-radius: 100%;
  border: solid gray 1px;
  padding: 4px;
  color:#fff;
  transform: scale(1);
  margin: 5px;
  cursor: pointer;
}

.user-icons:hover {
  color:#0d6efd;
  transform: scale(1.2);
}

.link-no-underline:hover .info-icon {
  color: #0d6efd;
}

.main-options {
  margin: 0px;
  cursor: pointer;
}

.icon-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-icon {
  margin-right: 10px;
}

.main-options a {
  color: #D3D3D3;
}

.main-options a:hover {
  color: #fff;
}

.icon-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Garante que o conteúdo ocupa toda a largura disponível */
  border: 1px solid rgba(255, 255, 255, 0.05); /* Cor da borda suave */
  padding: 12px;
}

.info-menu-icon {
  margin-left: 8px;
}

.expand-icon {
  margin-left: 10px;
  margin-right: 5px;
}

.link-no-underline.active {
  color: #fff;
}

.link-no-underline.active .info-icon {
  color: #0d6efd;
}

.left-content.open-collapse .info-menu-icon {
  color: #0d6efd;
}

.configurations-main.active .user-icons {
  color: #0d6efd;
  transform: scale(1.2);
}

.info-container {
  padding: 25px;
}

.btn-admin {
  background: #0040FF;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-order-remove {
  background: #b00b0b;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-admin:hover {
  background: #0040FF;
  border: none;
  color: #fff;
  transform: scale(1.1);
}

.btn-order-remove:hover {
  background: #b00b0b;
  border: none;
  color: #fff;
  transform: scale(1.1);
}

.custom-tabs-admin .nav-link.active,
.custom-tabs-admin .nav-item.show .nav-link {
  border-bottom: 2px solid #0d6efd;
  background-color: rgb(248,249,250);
}

.image-container {
  position: relative;
}

.image-container-products {
  position: relative;
  height: 200px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3); /* Cor da borda suave */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay-products {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container-products:hover .overlay-products {
  opacity: 1;
}

.modal-body-adm {
  padding: 15px;
  padding-bottom: 0px;
}

.btn-group {
  background: #fff;
  border: solid black 1px;
  color: #000;
  margin-right: 5px;
}

.btn-group:hover {
  background: #000;
  border: solid white 1px;
  color: #fff;
}

.btn-table-pagination,
.btn-table-pagination:disabled {
  background: transparent;
  color: black;
  border: black;
  transform: scale(1);
}

.btn-table-pagination:hover {
  background: transparent;
  color: #0d6efd;
  border: black;
  transform: scale(1.2);
}

.table-products {
  font-size: 14px;
}

#flexSwitchCheckDefaultAdm:checked {
  border: none;
  background-color: #0d6efd; 
  transform: scale(1);
}

.btn-set-main {
  background: #fff;
  border: solid black 1px;
  color: #000;
  margin-right: 10px;
}

.btn-set-main:hover {
  background: #000;
  border: solid white 1px;
  color: #fff;
}

.selected-image {
  border: 5px solid #0d6efd; /* ou a cor desejada */
}

.btn-success {
  background: #03a04c;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-success:hover {
  background: #03a04c;
  border: none;
  color: #fff;
  transform: scale(1.2);
}

.btn-error {
  background: #f60100;
  border: none;
  color: #fff;
  transform: scale(1);
}

.btn-error:hover {
  background: #f60100;
  border: none;
  color: #fff;
  transform: scale(1.2);
}